.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #204c71;
  color: #fff;
}

.background img {
  margin-bottom: 2em;
  max-width: 33%;
  height: auto;
}

.background h1 {
  font-size: large;
}

@media (min-width: 600px) {
  .background img {
    margin-bottom: 3em;
    max-width: 28%;
  }
  .background h1 {
    font-size: x-large;
  }
}

@media (min-width: 900px) {
  .background img {
    max-width: 22%;
  }
  .background h1 {
    font-size: xx-large;
  }
}
