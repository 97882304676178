@import "_var";

.background {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: $limit;
	color: $limit-text;
	img {
		margin-bottom: 2em;
		max-width: 33%;
		height: auto;
	}
	h1 {
		font-size: large;
	}
	@media (min-width: 600px) {
		img {
			margin-bottom: 3em;
			max-width: 28%;
		}
		h1 {
			font-size: x-large;
		}
	}
	@media (min-width: 900px) {
		img {
			max-width: 22%;
		}
		h1 {
			font-size: xx-large;
		}
	}
}
